
import { ApiDetails, HttpAuth } from "./config.interfaces";

export const api: ApiDetails = {
	base: "/api/",
	key: "eKabd7G;bd",
	suffixSplitChars: ["%", ".", ","],
};

export const httpAuth: HttpAuth = {
	user: "",
	pass: "",
};

export const imageSizes = {
	thumb: {
		mode: "resize",
		width: 640,
		height: 640,
		quality: 92,
	},
	half: {
		mode: "resize",
		width: 1280,
		height: 1280,
		quality: 92,
	},
	large: {
		mode: "resize",
		width: 2560,
		height: 2560,
		quality: 88,
	},
};

export const mediaPath = "/media/";

export const BACKEND_BASE_URL = "https://admin.findingyou.co";

export const BASE_URL_FOR_MEDIA = "https://mobile.findingyou.co/media/";

export const MAP_API_KEY = "AIzaSyBv8Sh2qpqu9O--2MxopOKB877sV_oox54";

export const HIDE_MOBILE_TAB_LANGUAGE_DETAILS_CONTENT = true;

export const DISABLE_FORM = true;
